
.navbar{
    background-color: white;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 1px;
    box-shadow: 0px 3px 8px #a23c3f;
    position: absolute;
    top: 0;
    padding: 5px 0px 5px 0px;
    width: 100%;
    z-index: 100;
}

.navbar.dark-mode {
    background-color: var(--dark-background);
    color:var(--dark-text-color);
}
.navbar-top{
    
    display: grid;
    grid-template-columns: 180px 1fr;
    column-gap: 30px;
    max-width: 1750px;
    margin: auto;
}
.navbar-top>a>img, .navbar-mobile>div>a>img{
    
    padding-left: 5px;
}

.navbar-mobile {
    display: flex;
    justify-content: space-between;
}
.nav-item-wrapper{
    display: grid;
    grid-template-columns: 1fr 275px;
    column-gap: 15px;
    padding-top: 7px;
    
}
.nav-left{
    display: flex;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}
.nav-right {
    display: flex;
    column-gap: 16px;
    justify-content: end;
}
.nav-item{
    cursor: pointer;
    position: relative;
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 1.35rem;
    letter-spacing: 2px;
    color: #000000e3;
    text-align: center;
    font-variant: small-caps;
    font-size: 1.4rem;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
}

.nav-item.small>a {
    font-size: 1.4rem;
}
.mobile-nav-item-wrapper>.nav-item{
    display: block;
}

.dark-mode .nav-item {
    color: white;
}

.navbar-text, .navbar-bottom{
    text-align: right;
    margin-right: 20px;
    line-height: 1.5;
}
.navbar-text{
    display: grid;
    grid-template-columns: 1fr 180px 120px;
}

.nav-dropdown{
    position: absolute;
    top: 38px;
    display: grid;
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    width: 250px;
}

.dark-mode .nav-dropdown {
    background-color: #42424c;
}
.notification-dropdown{
    right: 0px;
}
.nav-dropdown-right{
    right: 0px;
}
.nav-dropdown>a, .nav-item>a{
    text-decoration: none;
}




.navbar-bottom{
    font-size: 1.1rem;
    letter-spacing: 1px;;
}
.navbar-rank-logo{    
    margin-right: 5px;
    margin-left: 5px;
}

.dark .navbar-rank-logo {
    filter: invert(1);

}

.mobile-nav-item-wrapper>.nav-item{
    font-size: 1.7rem;
}

.mobile-dropdown{
    position: absolute;
    background-color: white;
    max-width: 300px;
    padding: 5px;
    box-shadow: 2px 4px 5px black;
    top: 40px;
}

.dark-mode .mobile-dropdown {    
    background-color: #42424c;
}
.mobile-nav-dropdown{
    padding-left: 10px;
}
.nav-item:hover{
    color: gray;
}

@media only screen and (max-width: 1175px){    
    .navbar-text{
        grid-template-columns: 1fr 1fr;
    }
    .nav-item-wrapper{
        grid-template-columns: 1fr 1fr;
    }
    .navbar{
        padding: 0px;
    }
    .navbar-top{
        display: none;
    }    
}
@media only screen and (min-width: 1175px){
    .navbar-mobile{
        display: none;
    }
}

.notifications-indicator{
    cursor: pointer;
    background-color: red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 14px;
    font-family: monospace;
    line-height: 1.4;
    color: white;
    font-weight: normal;
    position: absolute;
    top: -6px;
    right: -18px;
    z-index: -1;
}
.nav-dropdown-right>.nav-item {
    margin: auto;
}

.down-arrow {
    font-size: .9rem;
}

.dark-mode a {
    color: white;
}
@media only screen and (max-width: 1900px){
    .nav-item.right-most{
        margin-right: 48px
    }
}

.dark .menu-icon {
    filter: invert(1);
}