
:root {
    --empire-light-blue: #6581a3;
    --empire-dark-blue: #475971;
    --gray: #f0eeee ;
    --dark-red: rgb(158, 23, 23);
    --button-shadow: 1px 1px 5px black, 2px 2px 5px white;
    --dark-button-text-color: rgb(231, 231, 231);
    --dark-button-bg: #31313d;
    --dark-background: #1a1a21;

}

.dark a {
    
    color: var(--dark-button-text-color);
}

a{
    color: black;
}
a:hover{
    color: gray;
}
.unit-portrait{
    position: absolute;
    left: -20px;
    top: -8px;border-radius: 5000px;
    z-index: 5;
    border: 2px solid black;
}
.dark .unit-portrait{
    border: 2px solid var(--dark-button-text-color);
}
.rebel-border{
    border: 2px solid #750000;
}
.mercenary-border{
    border: 2px solid #6c6668;
}

.empire-border{
    border: 2px solid #6580a0;
}
.separatist-border{
    border: 2px solid #314894;
}
.republic-border{
    border: 2px solid #c8b595;
}

.header{
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
}
.page-background{
    background: #f1f1f1;
    padding-top: 60px;
    padding-bottom: 200px;
    min-height: 100vh;
}

.page-background.dark-mode, .dark .page-background{
    background: var(--dark-background);
}

@media only screen and (max-width: 500px){    
    .page-background{
        padding-top: 20px;
    }
}


@media print
{    
    .no-print, .no-print *, .footer, .footer *
    {
        display: none !important;
    }
}
h2{
    margin: 0px;
}

.login-page{
    background-color: var(--gray);
    padding: 20px;
    text-align: center;
}
.more-button{
    width: 100%;
    text-align: center;
    background-color: var(--gray);
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
}
.patreon-popup {
    background-color: white;
    height: 100%;
    padding-right: 30px;
    padding-left: 30px;
}

.patreon-overlay {
    position: fixed;
    left: 0px;
    bottom: 55px;
    width: 100%;
    max-width: 92vw;
    margin: auto;
    margin: auto;
    background-color: white;
    z-index: 500;
    border: 2px solid black;
    padding: 10px;
    text-align: center;
}
.patreon-close-button {
    position: absolute;
    top: 0px;
    right: 25px;
    cursor: pointer;
    font-size: 2rem;
}

.unit-page{
    background-color: var(--gray);
    padding: 20px;
}

.page{    
    background-color: var(--gray);
    padding: 50px;
}

.question{
    font-weight: bold;
    margin-top: 20px;
}
.unit-title {
    font-size: .65rem;
    line-height: .4;
    text-transform: uppercase;
    font-style: italic;
}

.unit-title.long {
    font-size:.55rem;
}

.tooltip-wrapper {
    position: relative;
}

.tooltip {
    display: none;
    position: absolute;
    top: -48px;
    left: 0px;
    background-color: white;
    border: 1px solid black;
    padding: 3px;
    pointer-events: none;
    border-radius: 8px;
    z-index: 5;
}
.dark .tooltip {
    background-color: var(--dark-button-bg);
}
.tooltip-wrapper:hover > .tooltip {
    display: block;
}

.backdrop {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.212);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 700;
  }

  .modal {
    z-index: 7;
    background-color: white;
    padding: 12px;
    border-radius: 5px;
    max-width:100vw;
    max-height:100vh;
    overflow: auto;
  }

  .ticker {
    text-align: center;
    color: rgb(163 163 163);
    margin-top: 24px;
  }

  .dark-mode>.ticker {
    background: var(--dark-button-bg);
  }

  
.save-button{
    border: none;
    box-shadow: 1px 1px 3px black, 2px 2px 2px white;
    background-color: #2e5da5;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    color:white;
    font-size: 1.05rem;
    margin-top: 5px;
    cursor:pointer;
}


.add-button, .delete-button{    
    height: 35px;
    width: 35px;
    border-radius: 100%;
    margin-top: 5px;    
    display: grid;
    place-items: center;
}
@media only screen and (max-width: 500px){    
    .delete-button{
        width: 30px;
        height: 30px;
    }
}
.add-button>span, .delete-button>span{    
    font-size: 20px;
    font-weight: bold;
    font-family: monospace;
}

.delete-button{
    color: var(--dark-red);    
    text-shadow: 1px 1px 1px black, 2px 2px 2px white;
    background-color: white;
    box-shadow: 1px 1px 5px black, 2px 2px 5px white;
    padding-right: 2px;
}
.delete-button.dark-mode{
    background-color: var(--dark-button-bg);
    text-shadow: none;
    border: 1px solid var(--dark-button-text-color);
    color: rgb(221 71 71);
    border: 1px solid rgb(126 126 126);
    box-shadow: none;
}
.add-button{
    border: 1px solid white;
    cursor: pointer;
    background-color: white;
}

.add-button.dark-mode{
    background-color: var(--dark-button-bg);
    color: var(--dark-button-text-color);
    border:none;
}

.custom-unit-page, .custom-command-page{
    background-color: var(--gray);
    padding-top: 50px;
    padding-bottom: 50px;
}

.pill-button {
    border-radius: 18px;
    background: white;
    border-width: 1px;
    padding: 3px 6px;
    margin-right: 6px;
    cursor: pointer;
    margin-bottom: 6px;
}


.dark .pill-button {
    background: var(--dark-button-bg);
    color: var(--dark-button-text-color);
}


.pill-button.active {
    background: var(--empire-light-blue);
    color: white;
}

.container {
    max-width: 1750px;
    margin: auto;
    padding: 32px;
    background: white;
}

.dark .container {
    background: var(--dark-background);
    color: var(--dark-button-text-color);
}
.dark .login-page {
    background: var(--dark-background);
    color: var(--dark-button-text-color);
}

.supporter .pw-tag {
    display: none;
}