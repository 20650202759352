.admin-add{
    border: 1px solid black;
    cursor: pointer;
    max-width: 200px;
    margin: 5px;
    padding: 5px;
}

.admin-keyword-wrapper{
    padding: 5px;
    margin-bottom: 8px;
}

.admin-columns{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;    
    background-color: var(--gray);
}

.admin-columns>div{
    overflow: auto;
    height: 1000px;
    padding: 10px;
    margin: auto;
}

.admin-row-wrapper{
    display : grid;
    grid-template-columns: 1fr 40px
}
.magnitude{
    grid-template-columns: 1fr 200px;
}
.magnitude-button{
    border: 1px solid black;
}


input[type="file"] {
    display: none;
}

.custom-file-upload {
    font-family: "Georgia" !important;
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    background-color: white;
    cursor: pointer;
    color: rgb(46, 46, 46);
    -webkit-box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.863);
    -moz-box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.808);
    box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.801);
    width: 120px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.644);
  
}
.upload-wrapper{
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

