.latest-lists-page{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    max-width: 2000px;
    margin: auto;
}

@media only screen and (max-width: 2200px){   
    .latest-lists-page{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 1400px){   
    .latest-lists-page{
        grid-template-columns: 1fr;
    }
}
.latest-lists-unit{
    border: 1px solid black;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 1.1rem;
}
.latest-lists-units-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
    row-gap: 10px;
}
.latest-lists-unit-wrapper {
    margin-bottom: 10px;
}
.latest-lists{
    background-color: var(--gray);
    max-width: 1400px;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    letter-spacing: 1px;
    position:relative;
}
.latest-lists>.faction-left{
    position: absolute;
    left: 5px;
    top: 5px
}
.latest-lists>.faction-right{
    position: absolute;
    right: 5px;
    top: 5px
}
.lastest-lists-upgrade {
    background-color: white;
    margin: 2px;
    margin-left: 5px;
    padding: 3px;
    border: 1px solid black;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 35px 1fr;
}
.latest-list-title{
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 20px;
    margin-right: 20px;
}
.latest-list-title>img{
    
    margin-bottom: -5px;
}
.latest-list-title>a{
    margin: 5px;
    margin-left: 20px;
    margin-right: 20px;
}
.latest-list-title>a{    
    color: #576ebd;
    text-decoration: none;
}

@media only screen and (max-width: 750px){   
    .latest-lists-units-wrapper{
        grid-template-columns: 1fr;
    }
}
.latest-lists-upgrades-wrapper{
    max-width: 90%;
    margin-left: auto;
}

.latest-lists-select{    
    font-size: 1.2rem;
    border-radius: 5px;
}
@media only screen and (max-width: 750px){   
    .latest-lists-select{
        font-size: .8rem;
    }
}

.latest-rank-logo{    
    position: absolute;
    left: 5px;
    top: 5px;
}

.filters{
    margin-bottom: 10px;
}

.list-builder-portrait.unit-portrait{
    top: -6px;
    left: -18px;
}

.list-builder-portrait.unit-portrait.list-portrait{
    right: -18px;
    left: inherit;
}
.latest-lists-username{
    font-size: 1.4rem;
    opacity: .8;    
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}
.latest-lists-points{
    font-size: 1rem;
    opacity: .6;
}
.view-list{
    margin: auto;
}


@media only screen and (max-width: 400px){   
    .latest-lists{
        padding: 5px;
    }
    .unit-portrait{
        left: 5px;
    }
}

.dark .latest-lists, .dark .lastest-lists-upgrade {
    background-color: var(--dark-background);
    color: var(--dark-button-text-color);
}

.dark .lastest-lists-upgrade>img {
    filter: invert(1);
}

.dark .latest-lists-unit {
    background-color: var(--dark-background);
    color: var(--dark-button-text-color);
}