
.user-lists{
    background-color: white;
}
.collection{
    text-align: center;
    display: flex;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 32px;
}
@media only screen and (max-width: 600px){
    .collection{
        grid-template-columns: 1fr;
    }
}
.collection-wrapper{
    display: flex;
    margin: auto;
    width: 350px;
    max-width: 100vw;
    justify-content: space-between;
}
.collection-faction{
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}

.collection-section{
    display: grid;
    margin-bottom: 15px;
}
.collection-page{
    background-color: var(--gray);
    padding-top: 12px;
}
.user-list-row{
    text-align: center;
    max-width: 350px;
    margin: auto;
    margin-bottom: 5px;
}
.user-template-row{
    display: grid;
    grid-template-columns: 1fr 40px;
}
.user-list-name{
    font-size: 1.2rem;
    line-height: normal;
}
.user-list-faction-header{
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
    
}
.pack-info{
    position: fixed;
    top: 50px;
    text-align: center;
    background-color: white;    
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;
}
.close-button{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    font-size: 2rem;
    cursor: pointer;
    background-color: white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    font-family: monospace;
    padding-bottom: 6px;
    z-index: 801;
}

.dark .close-button {
    background-color: var(--dark-button-bg);
    color: var(--dark-button-text-color);
}

.pack-info>span>img{
    max-width: 100vw;
}

.collection-pack-name{
    border: 1px solid black;
    cursor: pointer;
    background-color: white;
    padding: 4px;
    margin: 5px;
    border-radius: 5px;
    white-space:nowrap;
    font-size: 1rem;
    box-shadow: 1px 1px 3px #0000005e, 2px 2px 3px #fff;    
    max-width: min(230px, calc(100vw - 120px));
    text-overflow: ellipsis;
    overflow: hidden;
}
.pack-quantity{
    font-weight: bold;
    margin-left: 5px;
}

.photo-update{
    text-align: center;
}


.profile-page{
    background-color: white;
    display: flex;
    column-gap: 10px;
    justify-content: space-around;
    flex-wrap: wrap;
}
.user-lists-header{
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
}
.pack-item-wrapper{
    display: inline-flex;
}
.pack-item-quantity{
    position: absolute;
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 3px;
}
.avatar-update{
    text-align: center;
}

.public-profile-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 700px){
    .public-profile-wrapper{
        grid-template-columns: 1fr;
    }
}

.public-profile-page{
    background-color: var(--gray);
    padding-top: 20px;
}

.public-profile-username{
    background-color: var(--gray);
    opacity: 1;
    margin-bottom: 20px;
    padding: 10px;
}
.increment-button{
    cursor: pointer;
    color: var(--dark-red);
}
table {
    border-collapse: collapse;
}
tr {
    border-bottom: 1px solid gray;
}

.custom-input, .custom-select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,.103);
    margin-bottom: 18px;
}

.cart-icon {
    font-size: 20px;
}

.dark .profile-page, .dark .user-lists {
    background-color: var(--dark-background);
    color: var(--dark-button-text-color);
}

.dark .user-list-row {
    background-color: var(--dark-button-bg);
}