
.comment-input{
    width: calc(100% - 120px);
    border: none;
    resize: none;
    font-size: 1.3rem;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1.5;
}
.comment-form>.save-button{
    margin-left: 10px;
}
.comment-form{
    max-width: 620px;
    display: flex;
    margin: auto;
}

.comment-author{
    text-align: right;
}

.comment-delete-button{
    color: red;
    cursor: pointer;
    text-align: right;
    font-size: .8rem;
}

.comment-username {
    font-weight: 600;
}
.comment-time{
    opacity: .8;
    font-size: .9rem;
}
.comment-wrapper{
    text-align: left;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px white, 1px 1px 4px black;
    margin-bottom: 5px;
    background-color: white;
    display: grid;
    grid-template-columns: 60px 1fr;
}


.dark .comment-wrapper {
    background-color: var(--dark-background);
    color: var(--dark-button-text-color);
}