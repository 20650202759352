.image-upload-preview{
    max-width: 500px;
}
.image-post{
    text-align: center;
    background-color: var(--gray);
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px white, 1px 1px 5px black;
}
.image-post>img{
    border-radius: 5px;
    box-shadow: 1px 1px 3px white, 1px 1px 3px black;
    max-width: 100%;
}

.image-posts-page{
    max-width: 1600px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
}


@media only screen and (max-width: 1000px){    
    .image-posts-page{
        grid-template-columns: 1fr;
    }
    .image-post{
        padding: 5px;
        margin: 5px;
    }
}

.user-image-posts{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.image-post-title{
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}
.image-post-subtitle{
    font-size: 1rem;
    opacity: .6;
}

.edit-image-page {
    background-color: var(--gray);
    padding: 10px;
    padding-bottom: 20px;
}

input[type="file" i] {
    font-size: 1.1rem;
}

@media only screen and (max-width: 600px){
    input[type="file" i]{
        font-size: 1rem;
    }
}

.dark .image-post {
    background-color: var(--dark-background);
    color: var(--dark-button-text-color);
}