.home-page-body{
    z-index: 2;
}

.home-page-image-posts>div>a>img{
    width: 700px;
    max-width: 100%;
    height: auto;
}
.home-page-image-posts{
    max-width: 1200px;
    padding: 20px;
    background-color: var(--gray);
    border: 1px solid black;
    box-shadow: 1px 1px 3px white, 1px 1px 4px black;
    text-align: center;
    margin: auto;
    overflow: auto;
}

.home-page-news{
    padding: 10px;
    background-color: var(--gray);
    box-shadow: 1px 1px 3px white, 1px 1px 4px black;
    border: 1px solid black;
    margin: 20px;
    max-width: 75vw;
}

@media only screen and (max-width: 1400px){  
    .home-page-news{
        margin: 5px;
        max-width: 100vw;
    }
}
.sub-header{
    opacity: .8;
    text-align: center;
    font-size: .9rem;
}

.home-page-faction-select{
    margin: 20px;
}


@media only screen and (max-width: 1400px){    
    .home-page-image-posts{
        padding: 0px;
    }
    .home-page-faction-select{
        margin: 10px;
    }
}
.background-glow{
    width: 100vw;
    position: fixed;
    bottom: 0px;
    background: linear-gradient(rgba(0, 0, 255, 0), rgba(0, 0, 255, 0.301));
    height: 150px;
    z-index: -1;
}
.home-background{
    z-index: -2;
}

.home-page-wrapper {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    max-width: 1750px;
    margin:auto;
    flex-wrap:wrap;
}
.home-footer {
    background-color: white;
    color: #000000b8;
    font-size: .8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
    padding: 10px 0px;
}

.news-body{
    line-height: 120%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.1rem;
}

.home-page-wrapper>div>div {
    border-top: 2px solid black;
    background-color: white;
    width: 800px;
    margin: auto;
    max-width: 100vw;
    box-shadow: 0px 3px 5px #a23c3f, 0px -3px 5px #a23c3f;
}
.dark .home-page-wrapper>div>div, .dark .home-page-news {
    background-color: var(--dark-background);
    color: var(--dark-button-text-color);
}

.faction-dropdown-background {
    position: absolute;
    width: 160px;
    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    z-index: 5;
    padding: 5px 0px;
    text-underline-offset: 4px;
    width: 100%;
    left: 0;
}

.faction-dropdown-background>a {
    margin-bottom: 3px;
}

.dark .faction-dropdown-background {
    background-color: var(--dark-background);
    color: var(--dark-button-text-color);
    border: 1px solid var(--dark-button-text-color);
}

.faction-dropdown-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -32px;
    color: black;
    font-size: 28px;
    text-shadow: 1px 1px 2px black;
}

.dark .faction-dropdown-button {
    color: var(--dark-button-text-color);
    text-shadow: none;
}

.faction-dropdown-button:hover {
    transform: scale(1.1);
}